import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { FisLoadingIndicator } from '@fis/ui-loading-indicator';

export const Page = () => {
  const { replace } = useRouter();

  useEffect(() => {
    if (replace) {
      replace('document-sets');
    }
  }, [replace]);
  return <FisLoadingIndicator />;
};

export default Page;
